'use client'

import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React from 'react'
import { useProgressBar } from '~/components/layout/progress-bar'
import { type ButtonProps, TouchTarget, styles } from '~/components/ui/button'
import { cn } from '~/lib/utils'

export function ProgressBarLink({
  href,
  className,
  prefetch = true,
  children,
}: {
  href: string
  className?: string
  prefetch?: boolean
  children: React.ReactNode
}) {
  const router = useRouter()
  const { start, done } = useProgressBar()

  return (
    <Link
      onClick={(e) => {
        // e.preventDefault()
        start()

        React.startTransition(() => {
          done()
          router.push(href)
        })
      }}
      href={href}
      className={className}
      prefetch={prefetch}
    >
      {children}
    </Link>
  )
}

export const ProgressButton = React.forwardRef(function Button(
  { color, outline, plain, className, children, ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const router = useRouter()
  const { start, done } = useProgressBar()

  const classes = cn(
    styles.base,
    outline ? styles.outline : plain ? styles.plain : cn(styles.solid, styles.colors[color ?? 'dark/zinc']),
    className,
  )

  return 'href' in props ? (
    <Link
      {...props}
      className={classes}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      onClick={(e) => {
        e.preventDefault()
        if (props.onClick) {
          props.onClick(e)
        }

        start()

        React.startTransition(() => {
          done()
          router.push(props.href)
        })
      }}
      prefetch={true}
    >
      <TouchTarget>{children}</TouchTarget>
    </Link>
  ) : null
})
